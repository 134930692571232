<template>

   <b-modal
      id="modal-accessory-add"
      centered
      title="Aggiungi Accessorio"
      title-class="text-primary-light"
      header-text-variant="primary-light"
      body-class="px-0"
      size="md"
      ok-only
      ok-title="Salva Modifiche"
      @ok="addAccessory"
      @hide="$emit('hide', $event)"
    >

        <template #default>
        
        <div class="p-2">
            <b-form-group label="Nome Accessorio">
                <b-form-input v-model="accessory_name"></b-form-input>
            </b-form-group>

            <b-form-group label="Stato Accessorio">
                <b-form-checkbox switch v-model="accessory_status"></b-form-checkbox>
            </b-form-group>
        </div>

        </template>

    </b-modal>
    
</template>

<script>

import {BRow, BCol, BModal, BImg, BFormInput, BFormGroup, BFormCheckbox} from 'bootstrap-vue';

import {Requests} from "@/api";

export default {

    components: {
        BRow,
        BCol,
        BModal,
        BImg,
        BFormInput,
        BFormGroup,
        BFormCheckbox,
    },

    data: () => ({
        accessory_name: '',
        accessory_status: '',
    }),

    methods: {
        open() {
            this.$bvModal.show('modal-accessory-add');
        },

        async addAccessory() {
      this.loading = true;

      let request_data = new FormData();

      request_data.append("accessories_name", this.accessory_name);
      request_data.append("service_status", this.accessory_status);

      try {
        await Requests.addService(request_data);
        this.$swal.fire({
          title: "Accessorio Creato",
          icon: "success",
          text: "Accessorio creato con successo!",
          customClass: {
            title: "text-primary-light",
            confirmButton: "btn btn-primary",
          },
        });
      } catch (err) {
        console.debug(err);
      }

      this.loading = false;
    },
    }

}

</script>
