<template>
  <b-modal id="modal-location-edit" centered title="Modifica Ubicazione" title-class="text-primary-light"
    header-text-variant="primary-light" body-class="px-0" size="md" ok-only ok-title="Salva Modifiche"
    @ok="editLocation">
    <template #default>
      <div class="p-2">
        <b-form-group label="Nome Ubicazione">
          <b-form-input v-model="location.nome_ubicazione"></b-form-input>
        </b-form-group>
        <b-form-group label="Ditta">
          <b-form-select required v-model="location.id_ditta">
            <b-form-select-option v-for="(b, i) in businesses" :key="b.id_ditta" :value="b.id_ditta">{{b.nome_ditta}}</b-form-select-option>
          </b-form-select>
        </b-form-group>
      </div>
    </template>
  </b-modal>
</template>

<script>
import {
  BRow,
  BCol,
  BModal,
  BImg,
  BFormInput,
  BFormGroup,
  BFormCheckbox,
  BFormSelect,
  BFormSelectOption

} from "bootstrap-vue";



import { Requests } from "@/api";

export default {
  components: {
    BRow,
    BCol,
    BModal,
    BImg,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
    BFormSelect,
    BFormSelectOption
  },

  data: () => ({
    location: {},
    originalLocation: {},
    businesses: []
  }),

  methods: {
    async open(data) {
      const response = await Requests.getBusiness();
      this.businesses = response.data;
      this.location = Object.assign(this.location, data);
      this.originalLocation = data;
      this.$bvModal.show("modal-location-edit");
    },
    async editLocation() {
      this.loading = true;

      let request_data = new FormData();

      request_data.append('id_ubicazione', this.location.id_ubicazione);
      request_data.append('nome_ubicazione', this.location.nome_ubicazione);
      request_data.append('id_ditta', this.location.id_ditta);

      try {
        const response = await Requests.editLocation(request_data);
        console.debug(response);
        await this.$swal.fire({
          title: 'Modifica Effettuata',
          icon: 'success',
          text: 'Ubicazione modificata con successo!',
          customClass: {
            title: 'text-primary-light',
            confirmButton: "btn btn-primary"
          },
        });
        this.originalLocation = Object.assign(this.originalLocation, this.location);
      } catch (err) {
        console.debug(err)
      }

      this.loading = false;
    }
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>