<template>

   <b-modal
      id="modal-accessory-edit"
      centered
      title="Modifica Accessorio"
      title-class="text-primary-light"
      header-text-variant="primary-light"
      body-class="px-0"
      size="md"
      ok-only
      ok-title="Salva Modifiche"
      @ok="editAccessory"
    >

        <template #default>
        
        <div class="p-2">
            <b-form-group label="Nome Accessorio">
                <b-form-input v-model="accessory.accessories_name"></b-form-input>
            </b-form-group>

            <b-form-group label="Stato Accessorio">
                <b-form-checkbox switch v-model="accessory.service_status" value="Attivo" unchecked-value="Non attivo"></b-form-checkbox>
            </b-form-group>
        </div>

        </template>

    </b-modal>
    
</template>

<script>

import {BRow, BCol, BModal, BImg, BFormInput, BFormGroup, BFormCheckbox} from 'bootstrap-vue';

import {Requests} from "@/api";

export default {

    components: {
        BRow,
        BCol,
        BModal,
        BImg,
        BFormInput,
        BFormGroup,
        BFormCheckbox,
    },

    data: () => ({
        accessory: {},
        originalAccessory: {},
    }),

    methods: {
        open(data) {
            this.accessory = Object.assign(this.accessory, data);
            this.originalAccessory = data;
            this.$bvModal.show('modal-accessory-edit');
        },

    async editAccessory() {
      this.loading = true;

      let request_data = new FormData();

      request_data.append("id_accessories", this.accessory.id_accessories);
      request_data.append("accessories_name", this.accessory.accessories_name);
      request_data.append("service_status", this.accessory.service_status);

      try {
        await Requests.editAccessory(request_data);
        await this.$swal.fire({
          title: "Modifiche Effettuate!",
          icon: "success",
          text: "Modifiche effettuate con successo!",
          customClass: {
            title: "text-primary-light",
            confirmButton: "btn btn-primary",
          },
        });
        this.originalAccessory = Object.assign(this.originalAccessory, this.accessory);
      } catch (err) {
        console.debug(err);
      }

      this.loading = false;
    },
    }

}

</script>
