<template>

   <b-modal
      id="modal-practice-edit"
      centered
      title="Modifica Tipologia Pratica"
      title-class="text-primary-light"
      header-text-variant="primary-light"
      body-class="px-0"
      size="md"
      ok-only
      ok-title="Salva Modifiche"
      @ok="editPractice"
    >

        <template #default>
        
        <div class="p-2">
            <b-form-group label="Nome Tipologia Pratica">
                <b-form-input v-model="practice.pratices_name"></b-form-input>
            </b-form-group>
        </div>

        </template>

    </b-modal>
    
</template>

<script>

import {BRow, BCol, BModal, BImg, BFormInput, BFormGroup, BFormCheckbox} from 'bootstrap-vue';

import {Requests} from "@/api";

export default {

    components: {
        BRow,
        BCol,
        BModal,
        BImg,
        BFormInput,
        BFormGroup,
        BFormCheckbox,
    },

    data: () => ({
        practice: {},
        originalPractice: {},
    }),

    props: {
        type: String
    },

    methods: {
        open(data) {
            this.practice = Object.assign(this.practice, data);
            this.originalPractice = data;
            this.$bvModal.show('modal-practice-edit');
        },

        async editPractice() {
      this.loading = true;

      let request_data = new FormData();

      request_data.append("id_pratices_type", this.practice.id_pratices_type);
      request_data.append("pratices_name", this.practice.pratices_name);

      try {
        await Requests.editPractice(request_data);
        await this.$swal.fire({
          title: "Modifiche Effettuate!",
          icon: "success",
          text: "Modifiche effettuate con successo!",
          customClass: {
            title: "text-primary-light",
            confirmButton: "btn btn-primary",
          },
        });
        this.originalPractice = Object.assign(this.originalPractice, this.practice);
      } catch (err) {
        console.debug(err);
      }

      this.loading = false;
    },
    }

}

</script>
