<template>

   <b-modal
      id="modal-vehicle-documents-edit"
      centered
      title="Modifica Documenti Veicolo"
      title-class="text-primary-light"
      header-text-variant="primary-light"
      dialog-class="max-content"
      body-class="p-0"
      hide-footer
      size="lg"
    >

    <template #default>

        <div class="p-2">
      
        <vue-good-table 
            :columns="columns"
            :rows="documents"
        >

            <template slot="table-row" slot-scope="props">

                <span v-if="props.column.field == 'icon'">
                    <feather-icon icon="FileTextIcon"></feather-icon>
                </span>

                <span v-else-if="props.column.field == 'actions'">
                    <b-button variant="outline-success">
                        <feather-icon icon="StarIcon" />
                        Visualizza PDF
                    </b-button>

                    <b-button variant="outline-warning" class="mx-2">
                        <feather-icon icon="StarIcon" />
                        Sostituisci
                    </b-button>

                    <b-button variant="outline-danger">
                        <feather-icon icon="StarIcon" />
                        Elimina
                    </b-button>
                </span>

                 <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                </span>

            </template>

        </vue-good-table>

        </div>

        <hr />

        <b-row class="p-2">
            <b-col md="12">
                <feather-icon class="UploadCloudIcon" />
                <h5 class="text-primary-light">Carica nuovo documento</h5>
            </b-col>

            <b-col md="5">
                <v-select></v-select>
            </b-col>

            <b-col md="5">
                <b-input-group>
                    <b-form-input placeholder="Carica File"></b-form-input>
                    <b-input-group-append>
                        <b-button variant="outline-secondary">Carica</b-button>
                    </b-input-group-append>
                </b-input-group>
            </b-col>

            <b-col md="2">
                <b-button block variant="primary">
                    Carica
                </b-button>
            </b-col>
        </b-row>

    </template>

    </b-modal>
    
</template>

<script>
import {BRow, BCol, BButton, BModal, BFormInput, BInputGroup, BInputGroupAppend} from 'bootstrap-vue';
import VSelect from 'vue-select'

import { VueGoodTable } from 'vue-good-table';
import 'vue-good-table/dist/vue-good-table.css'

export default {

    components: {
        BRow,
        BCol,
        BButton,
        BModal,
        BFormInput,
        BInputGroup,
        BInputGroupAppend,
        VueGoodTable,
        VSelect,
    },

    data: () => ({
        columns: [
            {
                label: '',
                field: 'icon',
            },
            {
                label: 'Tipologia documento',
                field: 'document_type',
            },
            {
                label: 'Data caricamento',
                field: 'upload_date',
            },
            {
                label: '',
                field: 'actions'
            }
        ],

        documents: [
            {
                document_type: 'Libretto',
                upload_date: '03/05/2020',
            }
        ],
    }),

    methods: {
        open() {
            this.$bvModal.show('modal-vehicle-documents-edit');
        }
    }

}

</script>