<template>

   <b-modal
      id="modal-activities-list"
      centered
      title="Elenco attività"
      title-class="text-primary-light"
      header-text-variant="primary-light"
      body-class="px-0"
      size="md"
      hide-footer
    >

        <template #default>
        
        <div class="p-2">
            

    <app-timeline>
    <app-timeline-item
      title="Goal Achieved"
      subtitle="All milestones are completed"
      icon="AwardIcon"
      time="few minutes ago"
      variant="primary"
    />

    <app-timeline-item
      title="Last milestone remain"
      subtitle="You are just one step away from your goal"
      icon="InfoIcon"
      time="3 minutes ago"
      variant="info"
    />

    <app-timeline-item
      title="Your are running low on time"
      subtitle="Only 30 minutes left to finish milestone"
      icon="ClockIcon"
      time="21 minutes ago"
      variant="warning"
    />

    <app-timeline-item
      title="Client Meeting"
      subtitle="New event has been added to your schedule"
      icon="UserIcon"
      time="36 minutes ago"
    />

    <app-timeline-item
      title="Product Design"
      subtitle="Product design added in workflow"
      icon="GridIcon"
      time="1 hour ago"
      variant="success"
    />
  </app-timeline>
        </div>

        </template>

    </b-modal>
    
</template>

<script>
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'

import {BRow, BCol, BModal, BImg, BFormInput} from 'bootstrap-vue';

export default {

    components: {
        AppTimeline,
        AppTimelineItem,
        BRow,
        BCol,
        BModal,
        BImg,
        BFormInput,
    },

    data: () => ({

    }),

    methods: {
        open() {
            this.$bvModal.show('modal-activities-list');
        }
    }

}

</script>
