<template>
  <b-modal
    id="modal-service-add"
    centered
    title="Aggiungi Servizio"
    title-class="text-primary-light"
    header-text-variant="primary-light"
    body-class="px-0"
    size="md"
    ok-only
    ok-title="Aggiungi"
    @ok="addService"
    @hide="$emit('hide', $event)"
  >
    <template #default>
      <div class="p-2">
        <b-form-group label="Nome Servizio">
          <b-form-input v-model="service_name"></b-form-input>
        </b-form-group>

        <b-form-group label="Stato Servizio">
          <b-form-checkbox
            switch
            v-model="service_status"
            value="Attivo"
            unchecked-value="Non attivo"
          ></b-form-checkbox>
        </b-form-group>
      </div>
    </template>
  </b-modal>
</template>

<script>
import {
  BRow,
  BCol,
  BModal,
  BImg,
  BFormInput,
  BFormGroup,
  BFormCheckbox,
} from "bootstrap-vue";

import { Requests } from "@/api";

export default {
  components: {
    BRow,
    BCol,
    BModal,
    BImg,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
  },

  data: () => ({
    service_name: "",
    service_status: "Non attivo",

    //Helpers

    loading: false,
  }),

  methods: {
    open() {
      this.$bvModal.show("modal-service-add");
    },

    async addService() {
      this.loading = true;

      let request_data = new FormData();

      request_data.append("service_name", this.service_name);
      request_data.append("service_status", this.service_status);

      try {
        await Requests.addService(request_data);
        this.$swal.fire({
          title: "Servizio Creato",
          icon: "success",
          text: "Servizio creato con successo!",
          customClass: {
            title: "text-primary-light",
            confirmButton: "btn btn-primary",
          },
        });
      } catch (err) {
        console.debug(err);
      }

      this.loading = false;
    },
  },
};
</script>
