<template>
  <b-modal
    id="modal-location-add"
    centered
    title="Aggiungi Ubicazione"
    title-class="text-primary-light"
    header-text-variant="primary-light"
    body-class="px-0"
    size="md"
    ok-only
    ok-title="Aggiungi"
    @ok="addLocation"
    @hide="$emit('hide', $event)"
  >
    <template #default>
      <div class="p-2">
        <b-form-group label="Nome Ubicazione">
          <b-form-input v-model="nome_ubicazione"></b-form-input>
        </b-form-group>
      </div>
    </template>
  </b-modal>
</template>

<script>
import {
  BRow,
  BCol,
  BModal,
  BImg,
  BFormInput,
  BFormGroup,
  BFormCheckbox,
} from "bootstrap-vue";

import {Requests} from "@/api";

export default {
  components: {
    BRow,
    BCol,
    BModal,
    BImg,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
  },

  data: () => ({
    nome_ubicazione: '',

    //Helpers

    loading: false,
  }),

  methods: {
    open() {
      this.$bvModal.show("modal-location-add");
    },

    async addLocation() {
        this.loading = true;

        let request_data = new FormData();

        request_data.append('nome_ubicazione', this.nome_ubicazione)

        try {
            const response = Requests.addLocation(request_data);
            console.debug(response);
            this.$swal.fire({
                    title: 'Ubicazione Creata',
                    icon: 'success',
                    text: 'Ubicazione creata con successo!',
                    customClass: {
                        title: 'text-primary-light',
                        confirmButton: "btn btn-primary"
                    },
                })
        } catch(err) {
            console.debug(err);
        }

        this.loading = false
    }
  },
};
</script>
