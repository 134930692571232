<template>

   <b-modal
      id="modal-partner-edit"
      centered
      title="Modifica Agenzia"
      title-class="text-primary-light"
      header-text-variant="primary-light"
      body-class="px-0"
      size="md"
      ok-only
      ok-title="Salva Modifiche"
      @ok="editPartner"
    >

        <template #default>
        
        <div class="p-2">
            <b-form-group label="Nome Agenzia">
                <b-form-input v-model="partner.agency_name"></b-form-input>
            </b-form-group>
        </div>

        </template>

    </b-modal>
    
</template>

<script>

import {BRow, BCol, BModal, BImg, BFormInput, BFormGroup} from 'bootstrap-vue';

import {Requests} from '@/api'

export default {

    components: {
        BRow,
        BCol,
        BModal,
        BImg,
        BFormInput,
        BFormGroup,
    },

    data: () => ({
        partner: {},
        originalPartner: {},

        //Helpers

        loading: false,
    }),

    methods: {
        open(data) {
            this.partner = Object.assign(this.partner, data);
            this.originalPartner = data;
            this.$bvModal.show('modal-partner-edit');
        },

        async editPartner() {
            this.loading = true;

            let request_data = new FormData();

            request_data.append('id_agency', this.partner.id_agency);
            request_data.append('agency_name', this.partner.agency_name);

            try {
                const response = await Requests.editPartner(request_data);
                console.debug(response);
                await this.$swal.fire({
                    title: 'Modifica Effettuata',
                    icon: 'success',
                    text: 'Agenzia modificata con successo!',
                    customClass: {
                        title: 'text-primary-light',
                        confirmButton: "btn btn-primary"
                    },
                });
                this.originalPartner = Object.assign(this.originalPartner, this.partner);
            } catch(err) {
                console.debug(err)
            }

            this.loading = false;
        }
    }

}

</script>
