<template>
  <b-modal id="modal-vehicle-photo" centered title="Foto Veicolo" title-class="text-primary-light"
    header-text-variant="primary-light" ok-only ok-title="Chiudi" size="lg">

    <div :class="[photos.length > 0 ? 'd-block' : 'd-none']">
      <div class="mb-2" style="width: 666.66px; margin: 0 auto;">
        <swiper id="fotoSwiperTop" ref="swiperTop" class="swiper-gallery gallery-top" :options="swiperOptions">
          <swiper-slide v-for="(data, index) in photos" :key="index">
            <b-img :src="data" height="500" />
          </swiper-slide>

          <div slot="button-next" class="swiper-button-next swiper-button-white" />
          <div slot="button-prev" class="swiper-button-prev swiper-button-white" />
        </swiper>
      </div>

      <!-- swiper2 Thumbs -->
      <swiper id="fotoSwiperThumbs" ref="swiperThumbs" class="swiper gallery-thumbs" :options="swiperOptionThumbs">
        <swiper-slide v-for="(data, index) in photos" :key="index">
          <b-img :src="data" fluid />
        </swiper-slide>
      </swiper>
    </div>

    <div :class="[photos.length == 0 ? 'd-block' : 'd-none']">
      <h4 class="text-center text-primary-light my-5">Non ci sono foto disponibili per questo veicolo.</h4>
    </div>
  </b-modal>
</template>

<script>
import { BImg, BModal } from "bootstrap-vue";
import { Requests } from "@/api";
import { Swiper, SwiperSlide } from "vue-awesome-swiper"
import Swal from "sweetalert2";
import 'swiper/css/swiper.css'

export default {
  components: {
    Swiper,
    SwiperSlide,
    BImg,
    BModal,
    Swal
  },

  data: () => ({
    photos: [],

    swiperOptions: {
      loop: false,
      loopedSlides: 5,
      spaceBetween: 10,
      centeredSlides: true,
      navigation: {
        nextEl: '#fotoSwiperTop .swiper-button-next',
        prevEl: '#fotoSwiperTop .swiper-button-prev',
      },
    },

    swiperOptionThumbs: {
      loop: false,
      loopedSlides: 5, // looped slides should be the same
      spaceBetween: 10,
      centeredSlides: true,
      slidesPerView: 4,
      touchRatio: 0.2,
      slideToClickedSlide: true,
    },
  }),

  methods: {
    open(data, type) {
      this.photos = [];
      this.getVehiclePhotos(data, type);
    },

    async getVehiclePhotos(auto, type) {
      if (auto.id_auto != self.lastAutoId) {
        let request_data = new FormData();

        request_data.append("id_auto", auto.id_auto);
        try {
          let res;
          if (type == 'foto') {
            res = await Requests.getAutoPhotos(request_data);
          } else {
            res = await Requests.getAutoExaminationPhotos(request_data);
          }
          this.photos = res.map(foto => foto.image_path);
          this.$nextTick(() => {
            const swiperTop = this.$refs.swiperTop.$swiper;
            const swiperThumbs = this.$refs.swiperThumbs.$swiper;
            swiperTop.controller.control = swiperThumbs;
            swiperThumbs.controller.control = swiperTop;

          })
        } catch (err) {
          Swal.fire('Attenzione', 'Non è stato possibile caricare le immagini', 'error');
          return;
        }
      }
      this.$bvModal.show("modal-vehicle-photo");
    },

  },
};
</script>

