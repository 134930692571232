<template>

  <b-modal id="modal-service-edit" centered title="Modifica Servizio" title-class="text-primary-light"
    header-text-variant="primary-light" body-class="px-0" size="md" ok-only ok-title="Salva Modifiche"
    @ok="editService">

    <template #default>

      <div class="p-2">
        <b-form-group label="Nome Servizio">
          <b-form-input v-model="service.service_name"></b-form-input>
        </b-form-group>

        <b-form-group label="Stato Servizio">
          <b-form-checkbox switch v-model="service.service_status" value="Attivo" unchecked-value="Non attivo">
          </b-form-checkbox>
        </b-form-group>
      </div>

    </template>

  </b-modal>

</template>

<script>

import { BRow, BCol, BModal, BImg, BFormInput, BFormGroup, BFormCheckbox } from 'bootstrap-vue';

import { Requests } from "@/api";

export default {

  components: {
    BRow,
    BCol,
    BModal,
    BImg,
    BFormInput,
    BFormGroup,
    BFormCheckbox,
  },

  data: () => ({
    service: {},
    originalService: {}
  }),

  methods: {
    open(data) {
      this.originalService = data;
      this.service = Object.assign({}, data);
      this.$bvModal.show('modal-service-edit');
    },

    async editService() {
      this.loading = true;

      let request_data = new FormData();

      request_data.append("id_service", this.service.id_service);
      request_data.append("service_name", this.service.service_name);
      request_data.append("service_status", this.service.service_status);

      try {
        await Requests.editService(request_data);
        await this.$swal.fire({
          title: "Modifiche Effettuate!",
          icon: "success",
          text: "Modifiche effettuate con successo!",
          customClass: {
            title: "text-primary-light",
            confirmButton: "btn btn-primary",
          },
        });
        this.originalService = Object.assign(this.originalService, this.service);
      } catch (err) {
        console.debug(err);
      }

      this.loading = false;
    },
  }

}

</script>
