<template>
  <section>
    <b-card>
      <template #header>
        <div class="d-flex align-items-center">
          <span class="text-primary">Da Pubblicare </span>
          <b-breadcrumb class="ml-2">
            <b-breadcrumb-item>
              <feather-icon
                icon="HomeIcon"
                scale="1.25"
                shift-v="1.25"
              ></feather-icon>
            </b-breadcrumb-item>
            <b-breadcrumb-item active> Parco Auto</b-breadcrumb-item>
            <b-breadcrumb-item active> Da Pubblicare</b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </template>

      <b-row class="mt-2">
        <b-col md="9"> </b-col>

        <b-col md="3">
          <div class="d-flex align-items-center mb-2">
            <span class="mr-1">Cerca</span>
            <b-form-input type="text" v-model="filter" size="md" debounce="500"/>
          </div>
        </b-col>
      </b-row>

      <b-table
        ref="table"
        :fields="fields"
        :items="carProvider"
        :busy="loading"
        :current-page="currentPage"
        :filter="filter"
        show-empty
        responsive
        striped
        @sort-changed="sortTable"
      >
         <template #table-colgroup="scope">
          <col
            v-for="field in scope.fields"
            :key="field.key"
            :style="{ width: field.key == 'opzioni' ? '800px' : '180px' }"
          >
         </template>
        <template #table-busy>
          <div class="text-center text-primary-light my-2">
            <b-spinner class="align-middle mr-2"></b-spinner>
            <strong>Caricamento dati delle auto...</strong>
          </div>
        </template>
        <template #empty>
          <div class="text-center">
            <h5 class="text-primary-light my-2">Non ci sono auto da mostrare</h5>
          </div>
        </template>
        <template #cell(opzioni)="data">
          
          <span class="mr-2 text-nowrap">
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template #button-content>
                <feather-icon
                  style="color: gray"
                  size="20"
                  icon="LayersIcon"
                ></feather-icon>
              </template>
              <b-dropdown-item @click="$refs.vehicle_photo_modal.open(data.item, 'foto')">Foto</b-dropdown-item>
              <b-dropdown-item @click="$refs.vehicle_photo_modal.open(data.item, 'perizia')">Foto Perizia</b-dropdown-item>
              <b-dropdown-item>Dettagli Auto</b-dropdown-item>
              <b-dropdown-item @click="$refs.vehicle_documents_modal.open(data.item)">Documenti Associati</b-dropdown-item>
            </b-dropdown>

            <b-dropdown
              class="mx-2"
              size="sm"
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template #button-content>
                <feather-icon
                  style="color: gray"
                  size="20"
                  icon="EditIcon"
                ></feather-icon>
              </template>
              <b-dropdown-item @click="$refs.vehicle_photo_edit_modal.open(data)">Modifica Foto</b-dropdown-item>
              <b-dropdown-item @click="$refs.vehicle_photo_examination_edit_modal.open(data)">Modifica Foto Perizia</b-dropdown-item>
              <b-dropdown-item>Modifica Dettagli Auto</b-dropdown-item>
              <b-dropdown-item @click="$refs.vehicle_documents_edit_modal.open(data)">Modifica Documenti Associati</b-dropdown-item>
              <b-dropdown-item @click="$refs.vehicle_costs_edit_modal.open(data)">Modifica Costi</b-dropdown-item>
              <b-dropdown-item>Modifica Optionals</b-dropdown-item>
              <b-dropdown-item>Modifica Prezzi</b-dropdown-item>
              <b-dropdown-item @click="$refs.vehicle_status_edit_modal.open(data)">Modifica Status</b-dropdown-item>
              <b-dropdown-item @click="$refs.vehicle_location_edit_modal.open(data.item.ubicazione)">Modifica Ubicazione</b-dropdown-item>
            </b-dropdown>

            <b-dropdown
              size="sm"
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
            >
              <template #button-content>
                <feather-icon
                  style="color: gray"
                  size="20"
                  icon="FileTextIcon"
                ></feather-icon>
              </template>
              <b-dropdown-item>Stampa Scheda</b-dropdown-item>
              <b-dropdown-item @click="$refs.vehicle_reservation_list_modal.open(data.item.id_auto)">Elenco Prenotazioni</b-dropdown-item>
              <b-dropdown-item>Gestione Pratiche</b-dropdown-item>
              <b-dropdown-item>Gestione Finanziamenti</b-dropdown-item>
            </b-dropdown>

            <b-button
            class="ml-2"
            size="sm"
            variant="primary-light"
            pill
            @click="$refs.vehicle_price_set_modal.open(data.item.id_auto)"
          >
            Imposta Prezzi
          </b-button>
          </span>

        </template>
      </b-table>

      <b-pagination
        v-if="items.count > perPage"
        v-model="currentPage"
        :per-page="perPage"
        :total-rows="items.count"
        align="right"
        size="sm"
        class="my-0"
      />
    </b-card>

    <!-- Modali -->

    <vehicle-price-set-modal ref="vehicle_price_set_modal" @update="getAutoToPublish"/>
    <vehicle-costs-edit-modal ref="vehicle_costs_edit_modal" />
    <vehicle-photo-edit-modal ref="vehicle_photo_edit_modal"/>
    <vehicle-photo-examination-edit-modal ref="vehicle_photo_examination_edit_modal" />
    <vehicle-documents-modal ref='vehicle_documents_modal'/>
    <vehicle-photo-modal ref="vehicle_photo_modal" />
    <vehicle-documents-edit-modal ref="vehicle_documents_edit_modal" />
    <vehicle-status-edit-modal ref="vehicle_status_edit_modal" />
    <vehicle-location-edit-modal ref="vehicle_location_edit_modal" />
    <vehicle-reservation-list-modal ref="vehicle_reservation_list_modal" />

  </section>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BBreadcrumb,
  BBreadcrumbItem,
  BCard,
  BCardTitle,
  BIcon,
  BDropdown,
  BDropdownItem,
  BTable,
  BModal,
  BForm,
  BFormInput,
  BFormGroup,
  BPagination,
  BSpinner,
} from "bootstrap-vue";

import {  
  VehiclePriceSetModal,
  VehicleCostsEditModal,
  VehicleDocumentsModal,
  VehicleDocumentsEditModal,
  VehiclePhotoEditModal,
  VehiclePhotoExaminationEditModal,
  VehiclePhotoModal,
  VehicleStatusEditModal,
  VehicleLocationEditModal,
  VehicleReservationListModal,
} from '@/components/modals'

import { Requests } from "@/api";

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BBreadcrumb,
    BBreadcrumbItem,
    BCard,
    BCardTitle,
    BIcon,
    BDropdown,
    BDropdownItem,
    BTable,
    BModal,
    BForm,
    BFormInput,
    BFormGroup,
    BPagination,
    BSpinner,
    VehiclePriceSetModal,
    VehicleCostsEditModal,
    VehicleDocumentsModal,
    VehicleDocumentsEditModal,
    VehiclePhotoModal,
    VehiclePhotoEditModal,
    VehiclePhotoExaminationEditModal,
    VehicleStatusEditModal,
    VehicleLocationEditModal,
    VehicleReservationListModal,
  },

  data: () => ({
    fields: [
      { key: "id_auto", label:"id", sortable: true },
      { key: "marca", label:"marca", sortable: true },
      { key: "modello", label:"modello", sortable: true},
      { key: "versione", label: "versione", sortable: true},
      { key: "immatricolazione_anno", label: "anno", sortable: true },
      { key: "chilometraggio", label: "km", sortable: true },
      { key: "targa", sortable: true },
      { key: "telaio", sortable: true },
      { key: "colore", sortable: true },
      "opzioni",
    ],

    items: [],

    //Helpers

    loading: false,
    currentPage: 1,
    perPage: 15,
    sortBy: 'id_auto',
    sortDesc: false,
    filter: null,
  }),

  methods: {
    async getAutoToPublish(page) {
      this.loading = true;

      page = page ?? this.currentPage;

      let request_data = new FormData();

      request_data.append("offset", (page - 1) * this.perPage);
      request_data.append("limit", this.perPage);
      request_data.append("filter", this.filter);
      request_data.append("sortBy", this.sortBy);
      request_data.append("sortDesc", this.sortDesc);

      try {
        const response = await Requests.getAutoToPublish(request_data);
        this.items = response;
      } catch(err) {
        console.debug(err);
      }

      this.loading = false;
    },

    async carProvider() {
      await this.getAutoToPublish();
      return this.items.data;
    },

    async sortTable(ctx) {
      this.sortBy = ctx.sortBy;
      this.sortDesc = ctx.sortDesc;
    },
  },
};
</script>

<style>
.b-dropdown button {
  padding: 0;
}
</style>

<style lang="scss">
    @import "@core/scss/vue/libs/vue-select.scss";
</style>
