<template>

   <b-modal
      id="modal-vehicle-location-edit"
      centered
      title="Modifica ubicazione"
      title-class="text-primary-light"
      header-text-variant="primary-light"
      body-class="px-0"
      ok-only
      ok-title="Salva Modifiche"
      size="md"
    >

        <template #default>
        
        <div class="p-2">
            <b-row>

                <b-col md="12">
                    <b-form-group label="Ubicazione attuale del veicolo">
                        <v-select input-id="id_ditta" label="nome_ubicazione" :options="locations" v-model="selected_location">

                        </v-select>
                    </b-form-group>
                </b-col>

            </b-row>

        </div>

        </template>

    </b-modal>
    
</template>

<script>
import {BRow, BCol, BModal, BImg, BFormGroup} from 'bootstrap-vue';
import {Requests} from '@/api';
import vSelect from 'vue-select';

export default {

    components: {
        BRow,
        BCol, 
        BModal,
        BImg,
        BFormGroup,
        vSelect,
    },

    data: () => ({
        selected_location: null,

        locations: [],
    }),

    created() {
        this.getLocations();
    },

    methods: {
        open(data) {
            this.selected_location = data;
            this.$bvModal.show('modal-vehicle-location-edit');
        },

        async getLocations() {
            try {
                const response = await Requests.getLocations();
                this.locations = response;
            } catch(err) {
                console.debug(err);
            }
        },
    }

}

</script>
