<template>

   <b-modal
      id="modal-partner-add"
      centered
      title="Aggiungi Azienda"
      title-class="text-primary-light"
      header-text-variant="primary-light"
      body-class="px-0"
      size="md"
      ok-only
      ok-title="Aggiungi"
      @ok="addPartner"
      @hide="$emit('hide', $event)"
    >

        <template #default>
        
        <div class="p-2">
            <b-form-group label="Nome Azienda">
                <b-form-input v-model="agency_name"></b-form-input>
            </b-form-group>
        </div>

        </template>

    </b-modal>
    
</template>

<script>

import {BRow, BCol, BModal, BImg, BFormInput, BFormGroup } from 'bootstrap-vue';

import {Requests} from "@/api";

export default {

    components: {
        BRow,
        BCol,
        BModal,
        BImg,
        BFormInput,
        BFormGroup,
    },

    data: () => ({
        agency_name: '',
    }),

    methods: {
        open() {
            this.$bvModal.show('modal-partner-add');
        },

        async addPartner() {
            this.loading = true;

            let request_data = new FormData();

            request_data.append('agency_name', this.agency_name);

            try {
                const response = await Requests.addPartner(request_data);
                console.debug(response);
                this.$swal.fire({
                    title: 'Agenzia Creata',
                    icon: 'success',
                    text: 'Agenzia creata con successo!',
                    customClass: {
                        title: 'text-primary-light',
                        confirmButton: "btn btn-primary"
                    },
                })
            } catch(err) {
                console.debug(err);
            }

            this.loading = false;
        }
    }

}

</script>
