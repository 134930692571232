<template>
  <b-modal
    id="modal-vehicle-reservation-list"
    centered
    title="Elenco Prenotazioni"
    title-class="text-primary-light"
    header-text-variant="primary-light"
    body-class="px-0"
    ok-only
    ok-title="Salva"
    size="xl"
  >
    <template #default>
      <div class="p-2">
        <b-row>
          <b-col v-if="reservations.length > 0" md="12">
            <b-table :fields="fields" :items="reservations"> </b-table>
          </b-col>

          <b-col v-else md="12">
            <h3 class="text-primary-light text-center">
              Non ci sono prenotazioni per quest'auto
            </h3>
          </b-col>
        </b-row>

        <b-row class="mt-5">
          <b-col md="12">
            <b-alert variant="primary-light" show>
              <h4 class="alert-heading">Crea una nuova prenotazione</h4>
              <div class="alert-body">
                Usa il form per creare una nuova prenotazione per il veicolo.
              </div>
            </b-alert>
          </b-col>
        </b-row>

        <b-row class="mt-3">
            <b-col md="6">
                <b-form-group label="Venditore">
                <v-select>

                </v-select>
                </b-form-group>
            </b-col>

            <b-col md="6">
                <b-form-group label="Durata Prenotazione">
                <v-select>

                </v-select>
                </b-form-group>
            </b-col>

        </b-row>

      </div>
    </template>
  </b-modal>
</template>

<script>
import { BAlert, BRow, BCol, BModal, BTable, BFormGroup } from "bootstrap-vue";
import vSelect from "vue-select";

import { Requests } from "@/api";

export default {
  components: {
    BAlert,
    BRow,
    BCol,
    BModal,
    BTable,
    vSelect,
    BFormGroup,
  },

  data: () => ({
    fields: [
      {
        key: "id_veicolo",
        sortable: true,
      },
      {
        key: "venditore",
        sortable: true,
      },
      {
        key: "durata",
        sortable: true,
      },
      {
        key: "data_prenotazione",
        sortable: true,
      },
      {
        key: "fine_prenotazione",
        sortable: true,
      },
      {
        key: "opzioni",
        sortable: false,
      },
    ],

    reservations: [],
  }),

  methods: {
    open(data) {
      this.getVehicleReservations(data);
      this.$bvModal.show("modal-vehicle-reservation-list");
    },

    async getVehicleReservations(data) {
      this.loading = true;

      try {
        const response = await Requests.getVehicleReservations(data);
        this.reservations = response;
      } catch (err) {
        console.debug(err);
      }

      this.loading = false;
    },
  },
};
</script>
