<template>
  <b-modal
    id="modal-vehicle-photo-examination-edit"
    centered
    title-class="text-primary-light"
    header-text-variant="primary-light"
    title="Modifica foto veicolo"
    ok-only
    size="lg"
  >
    <b-row>
      <b-col md="3">
        <b-card class="cursor-pointer">
          <div class="d-flex flex-column align-items-center">
            <feather-icon size="50" icon="ImageIcon" />
            <span class="mt-1">Aggiungi foto</span>
          </div>
        </b-card>
      </b-col>

      <b-col v-for="i in 5" :key="i" md="3">
        <b-card>
          <div class="d-flex justify-content-end mb-2">
            <feather-icon icon="RefreshCwIcon" class="mr-2" />
            <feather-icon icon="XIcon" />
          </div>

          <b-img fluid src="@/assets/images/logo.png" />
          <b-card-actions action-collapse> </b-card-actions>
        </b-card>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>
import { BRow, BCol, BCard, BModal, BImg } from "bootstrap-vue";

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BModal,
    BImg,
  },

  data: () => ({}),

  methods: {
    open() {
      this.$bvModal.show("modal-vehicle-photo-examination-edit");
    },
  },
};
</script>
