<template>

   <b-modal
      id="modal-financial-edit"
      centered
      title="Aggiungi Finanziaria"
      title-class="text-primary-light"
      header-text-variant="primary-light"
      body-class="px-0"
      size="md"
      ok-only
      ok-title="Aggiungi"
      @ok="editFinancial"
    >

        <template #default>
        
        <div class="p-2">
            <b-form-group label="Nome Finanziaria">
                <b-form-input v-model="financial.financial_name"></b-form-input>
            </b-form-group>
        </div>

        </template>

    </b-modal>
    
</template>

<script>

import {BRow, BCol, BModal, BImg, BFormInput, BFormGroup} from 'bootstrap-vue';

import {Requests} from "@/api";

export default {

    components: {
        BRow,
        BCol,
        BModal,
        BImg,
        BFormInput,
        BFormGroup,
    },

    data: () => ({
        financial: {},
        originalFinancial: {}
    }),

    methods: {
        open(data) {
            Object.assign(this.financial, data);
            this.originalFinancial = data;
            this.$bvModal.show('modal-financial-edit');
        },

        async editFinancial() {
            this.loading = true;

            let request_data = new FormData();

            request_data.append('id_financial', this.financial.id_financial)
            request_data.append('financial_name', this.financial.financial_name);

            try {
                const response = await Requests.editFinancial(request_data);
                console.debug(response);
                await this.$swal.fire({
                    title: 'Modifica Effettuata!',
                    icon: 'success',
                    text: 'Finanziaria modificata con successo!',
                    customClass: {
                        title: 'text-primary-light',
                        confirmButton: "btn btn-primary"
                    },
                });
                this.originalFinancial = Object.assign(this.originalFinancial, this.financial);
            } catch(err) {
                console.debug(err);
            }

            this.loading = false;
        }
    }

}

</script>
