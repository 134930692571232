<template>

   <b-modal
      id="modal-vehicle-documents"
      centered
      title="Documenti Veicolo"
      title-class="text-primary-light"
      header-text-variant="primary-light"
      ok-only
      size="lg"
    >
      
        <vue-good-table 
            :columns="columns"
            :rows="documents"
        >

            <template slot="table-row" slot-scope="props">

                <span v-if="props.column.field == 'icon'">
                    <feather-icon icon="FileTextIcon"></feather-icon>
                </span>

                <span v-else-if="props.column.field == 'actions'">
                    <b-button variant="outline-success">
                        <feather-icon icon="StarIcon" />
                        Visualizza PDF
                    </b-button>
                </span>

                 <span v-else>
                    {{ props.formattedRow[props.column.field] }}
                </span>

            </template>

        </vue-good-table>

    </b-modal>
    
</template>

<script>
import {BButton, BModal} from 'bootstrap-vue';

import { VueGoodTable } from 'vue-good-table';
import 'vue-good-table/dist/vue-good-table.css'

export default {

    components: {
        BButton,
        BModal,
        VueGoodTable,
    },

    data: () => ({
        columns: [
            {
                label: '',
                field: 'icon',
            },
            {
                label: 'Tipologia documento',
                field: 'document_type',
            },
            {
                label: 'Data caricamento',
                field: 'upload_date',
            },
            {
                label: '',
                field: 'actions'
            }
        ],

        documents: [
            {
                document_type: 'Libretto',
                upload_date: '03/05/2020',
            }
        ],
    }),

    methods: {
        open() {
            this.$bvModal.show('modal-vehicle-documents');
        }
    }

}

</script>
