<template>
  <b-modal
    id="modal-vehicle-price-set-edit"
    centered
    title="Imposta prezzi"
    title-class="text-primary-light"
    header-text-variant="primary-light"
    body-class="px-0"
    ok-only
    ok-title="Salva Modifiche"
    size="md"
    @ok="setPrice"
  >
    <template #default>
      <div class="py-2 px-1">
        <b-form>
          <b-form-group label="Prezzo di vendita al privato">
            <b-input-group prepend="€">
              <b-form-input
                type="number"
                placeholder="Specifica il nuovo prezzo per la vendita al privato"
                v-model="sell_price"
              ></b-form-input>
            </b-input-group>
          </b-form-group>

          <b-form-group label="Prezzo da mostrare sul sito e portali web">
            <b-input-group prepend="€">
              <b-form-input
                type="number"
                placeholder="Specifica il nuovo prezzo per la vendita sui portali web"
                v-model="web_price"
              ></b-form-input>
            </b-input-group>
          </b-form-group>

          <b-form-group label="Prezzo di vendita al rivenditore">
            <b-input-group prepend="€">
              <b-form-input
                type="number"
                placeholder="Specifica il nuovo prezzo per la vendita al rivenditore"
                v-model="reseller_price"
              ></b-form-input>
            </b-input-group>
          </b-form-group>
        </b-form>
      </div>
    </template>
  </b-modal>
</template>

<script>
import {
  BRow,
  BCol,
  BModal,
  BImg,
  BFormInput,
  BFormGroup,
  BInputGroup,
} from "bootstrap-vue";
import { ValidationObserver, ValidationProvider, localize } from "vee-validate";

import { Requests } from "@/api";

export default {
  components: {
    BRow,
    BCol,
    BModal,
    BImg,
    BFormInput,
    BFormGroup,
    BInputGroup,
    ValidationObserver,
    ValidationProvider,
  },

  created() {
    localize("it");
  },

  data: () => ({
    id_auto: null,
    sell_price: null,
    web_price: null,
    reseller_price: null,
  }),

  methods: {
    open(data) {
      this.id_auto = data;
      this.$bvModal.show("modal-vehicle-price-set-edit");
    },

    async setPrice() {
      this.loading = true;

      let request_data = new FormData();

      request_data.append("id_auto", this.id_auto);
      request_data.append("prezzo_vendita", this.sell_price);
      request_data.append("prezzo_web", this.web_price);
      request_data.append("prezzo_rivenditore", this.reseller_price);

      try {
        await Requests.autoPatch(request_data);
        this.$swal.fire({
          title: "Prezzi Impostati",
          icon: "success",
          text: "Prezzi impostati con successo!",
          customClass: {
            title: "text-primary-light",
            confirmButton: "btn btn-primary",
          },
        });
        this.$emit('update', null);
      } catch (err) {
        console.debug(err);
      }

      this.loading = false;
    },
  },
};
</script>
