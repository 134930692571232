<template>

   <b-modal
      id="modal-vehicle-costs-edit"
      centered
      title="Modifica costi"
      title-class="text-primary-light"
      header-text-variant="primary-light"
      body-class="px-0"
      ok-only
      ok-title="Salva Modifiche"
      size="md"
    >

        <template #default>
        
        <div class="p-2">
            <b-row>

                <b-col md="12" class="h4">
                    <span class="text-primary-light">Costi di ripristino dell'auto</span>
                </b-col>

                <b-col md="6">
                    <b-form-input placeholder="Imposta costi carrozzeria"></b-form-input>
                </b-col>

                <b-col md="6">
                    <b-form-input placeholder="Imposta costi meccanica"></b-form-input>
                </b-col>

            </b-row>

            <b-row class="mt-2">

                <b-col md="6">
                    <b-form-input placeholder="Imposta costi elettronica"></b-form-input>
                </b-col>

                <b-col md="6">
                    <b-form-input placeholder="Imposta altri costi di ripristino"></b-form-input>
                </b-col>
            </b-row>
        </div>

            <hr />

        <div class="p-2">
            <b-row>

                <b-col md="12" class="h4">
                    <span class="text-primary-light">Costi dei servizi</span>
                </b-col>

                <b-col md="6">
                    <b-form-input placeholder="Imposta costi collaudo"></b-form-input>
                </b-col>

                <b-col md="6">
                    <b-form-input placeholder="Imposta costi minivoltura"></b-form-input>
                </b-col>

            </b-row>

            <b-row class="mt-2">

                <b-col md="6">
                    <b-form-input placeholder="Imposta costi voltura"></b-form-input>
                </b-col>

                <b-col md="6">
                    <b-form-input placeholder="Imposta costi trasporto"></b-form-input>
                </b-col>
            </b-row>

            <b-row class="mt-2">
                <b-col md="6">
                    <b-form-input placeholder="Imposta altri costi per servizi"></b-form-input>
                </b-col>
            </b-row>
        </div>

        </template>

    </b-modal>
    
</template>

<script>
import {BRow, BCol, BModal, BImg, BFormInput} from 'bootstrap-vue';

export default {

    components: {
        BRow,
        BCol,
        BModal,
        BImg,
        BFormInput,
    },

    data: () => ({

    }),

    methods: {
        open() {
            this.$bvModal.show('modal-vehicle-costs-edit');
        }
    }

}

</script>
