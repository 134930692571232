<template>

   <b-modal
      id="modal-vehicle-status-edit"
      centered
      title="Modifica status"
      title-class="text-primary-light"
      header-text-variant="primary-light"
      body-class="px-0"
      ok-only
      ok-title="Salva Modifiche"
      size="md"
    >

        <template #default>
        
        <div class="p-2">
            <b-row>

                <b-col md="12">
                    <b-form-group label="Status attuale del veicolo">
                        <v-select>

                        </v-select>
                    </b-form-group>
                </b-col>

            </b-row>

        </div>

        </template>

    </b-modal>
    
</template>

<script>
import {BRow, BCol, BModal, BImg, BFormGroup} from 'bootstrap-vue';
import vSelect from 'vue-select';

export default {

    components: {
        BRow,
        BCol, 
        BModal,
        BImg,
        BFormGroup,
        vSelect,
    },

    data: () => ({

    }),

    methods: {
        open() {
            this.$bvModal.show('modal-vehicle-status-edit');
        }
    }

}

</script>
